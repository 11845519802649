import type * as Sentry from "@sentry/nextjs";
import invariant from "ts-invariant";

const environment = process.env.VERCEL_ENV ?? "development";
const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN ?? "";
const sentryIsEnabled = process.env.NEXT_PUBLIC_SENTRY_IS_ENABLED === "true";

if (sentryIsEnabled) {
  invariant(sentryDsn !== "", "`process.env.NEXT_PUBLIC_SENTRY_DSN` is set");
}

export const sentrySharedConfigOptions: Parameters<typeof Sentry.init>[0] = {
  debug: false,
  dsn: sentryDsn,
  enabled: sentryIsEnabled,
  environment,
  tracesSampleRate: 1,
};
