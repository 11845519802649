var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1YvSOqYP86UFzo5I2LjTS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { sentrySharedConfigOptions } from "./sentry.shared.options";

Sentry.init({
  ...sentrySharedConfigOptions,
  integrations: [
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
});
